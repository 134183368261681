"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLocal = void 0;
const listLocal = [{
        id: '1',
        local: 'Pousada Por Do Sol'
    }, {
        id: '2',
        local: 'Cabanha do Bambu'
    }];
const getLocal = (id) => {
    let local = listLocal.find((e) => e.id === id);
    console.log(local);
    return local;
};
exports.getLocal = getLocal;
