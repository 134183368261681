"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const redirect_1 = require("../../utils/redirect");
const LinkList = () => {
    const renderList = () => {
        return redirect_1.listItem.map((element) => {
            return ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsxs)("a", Object.assign({ className: `button ${element.className}`, href: `${element.link}`, target: "_blank", rel: "noopener" }, { children: [(0, jsx_runtime_1.jsx)("img", { src: element.icon, className: "icon", alt: `${element.name}` }), element.name] }), element.id), (0, jsx_runtime_1.jsx)("br", {})] }, element.id));
        });
    };
    return (0, jsx_runtime_1.jsx)("div", { children: renderList() });
};
exports.default = LinkList;
